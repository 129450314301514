define("ember-models-table/components/models-table/pagination-simple", ["exports", "ember-models-table/templates/components/models-table/pagination-simple"], function (_exports, _paginationSimple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Simple navigation (first, prev, next, last) used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * {{#models-table data=data columns=columns as |mt|}}
   *   {{#mt.footer as |footer|}}
   *     {{footer.pagination-simple}}
   *     {{! ... }}
   *   {{/mt.footer}}
   *   {{! .... }}
   * {{/models-table}}
   * ```
   *
   * @class ModelsTablePaginationSimple
   * @namespace Components
   * @extends Ember.Component
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _paginationSimple.default,
    classNameBindings: ['themeInstance.paginationWrapper', 'themeInstance.paginationWrapperDefault'],
    /**
     * Bound from {{#crossLink "Components.ModelsTable/currentPageNumber:property"}}ModelsTable.currentPageNumber{{/crossLink}}
     *
     * @property currentPageNumber
     * @type number
     * @default null
     */
    currentPageNumber: null,
    /**
     * Bound from {{#crossLink "Components.ModelsTable/arrangedContentLength:property"}}ModelsTable.arrangedContentLength{{/crossLink}}
     *
     * @property recordsCount
     * @type number
     * @default null
     */
    recordsCount: null,
    /**
     * Bound from {{#crossLink "Components.ModelsTable/pagesCount:property"}}ModelsTable.pagesCount{{/crossLink}}
     *
     * @property pagesCount
     * @type number
     * @default null
     */
    pagesCount: null,
    /**
     * Bound from {{#crossLink "Components.ModelsTable/currentPageNumberOptions:property"}}ModelsTable.currentPageNumberOptions{{/crossLink}}
     *
     * @property currentPageNumberOptions
     * @type object[]
     * @default null
     */
    currentPageNumberOptions: null,
    /**
     * Bound from {{#crossLink "Components.ModelsTable/showCurrentPageNumberSelect:property"}}ModelsTable.showCurrentPageNumberSelect{{/crossLink}}
     *
     * @property showCurrentPageNumberSelect
     * @type boolean
     * @default null
     */
    showCurrentPageNumberSelect: null,
    /**
     * Closure action {{#crossLink "Components.ModelsTable/actions.gotoCustomPage:method"}}ModelsTable.actions.gotoCustomPage{{/crossLink}}
     *
     * @event goToPage
     */
    goToPage: null,
    /**
     * Bound from {{#crossLink "Components.ModelsTable/pageSize:property"}}ModelsTable.pageSize{{/crossLink}}
     *
     * @property pageSize
     * @type number
     * @default null
     */
    pageSize: null,
    /**
     * Bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     *
     * @property themeInstance
     * @type object
     * @default null
     */
    themeInstance: null,
    /**
     * Are buttons "Back" and "First" enabled
     *
     * @type boolean
     * @property gotoBackEnabled
     */
    gotoBackEnabled: Ember.computed.gt('currentPageNumber', 1),
    /**
     * Are buttons "Next" and "Last" enabled
     *
     * @type boolean
     * @property gotoForwardEnabled
     */
    gotoForwardEnabled: Ember.computed('currentPageNumber', 'pagesCount', function () {
      return Ember.get(this, 'currentPageNumber') < Ember.get(this, 'pagesCount');
    }),
    /**
     * @property inputId
     * @type string
     * @private
     */
    inputId: Ember.computed('elementId', function () {
      return "".concat(Ember.get(this, 'elementId'), "-page-number-select");
    }),
    actions: {
      noop: function noop() {},
      gotoFirst: function gotoFirst() {
        if (!Ember.get(this, 'gotoBackEnabled')) {
          return;
        }
        Ember.get(this, 'goToPage')(1);
      },
      gotoPrev: function gotoPrev() {
        if (!Ember.get(this, 'gotoBackEnabled')) {
          return;
        }
        var currentPageNumber = Ember.get(this, 'currentPageNumber');
        if (currentPageNumber > 1) {
          Ember.get(this, 'goToPage')(currentPageNumber - 1);
        }
      },
      gotoNext: function gotoNext() {
        if (!Ember.get(this, 'gotoForwardEnabled')) {
          return;
        }
        var currentPageNumber = Ember.get(this, 'currentPageNumber');
        var pageSize = parseInt(Ember.get(this, 'pageSize'), 10);
        var arrangedContentLength = Ember.get(this, 'recordsCount');
        if (arrangedContentLength > pageSize * (currentPageNumber - 1)) {
          Ember.get(this, 'goToPage')(currentPageNumber + 1);
        }
      },
      gotoLast: function gotoLast() {
        if (!Ember.get(this, 'gotoForwardEnabled')) {
          return;
        }
        var pageSize = parseInt(Ember.get(this, 'pageSize'), 10);
        var arrangedContentLength = Ember.get(this, 'recordsCount');
        var pageNumber = arrangedContentLength / pageSize;
        pageNumber = 0 === pageNumber % 1 ? pageNumber : Math.floor(pageNumber) + 1;
        Ember.get(this, 'goToPage')(pageNumber);
      },
      gotoPage: function gotoPage(pageNumber) {
        Ember.get(this, 'goToPage')(pageNumber);
      }
    }
  });
});