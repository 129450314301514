define("ember-changeset-conditional-validations/validators/sometimes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateSometimes;
  function validateSometimes(validators, condition) {
    return validators.map(function (validator) {
      return function (key, newValue, oldValue, changes, content) {
        if (condition(changes, content)) {
          return validator(key, newValue, oldValue, changes, content);
        }
        return true;
      };
    });
  }
});