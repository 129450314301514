define('ember-simple-auth-jwt/authenticators/jwt', ['exports', 'fetch', 'ember-simple-auth/authenticators/base'], function (exports, _fetch, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _base.default.extend({
    /**
      The endpoint on the server that the authentication request is sent to.
      @property serverTokenEndpoint
      @type String
      @default '/api/token-auth'
      @public
    */
    serverTokenEndpoint: '/api/token-auth',

    /**
      The endpoint on the server that the refresh request is sent to.
      @property serverRefreshTokenEndpoint
      @type String
      @default '/api/token-refresh'
      @public
    */
    serverRefreshTokenEndpoint: '/api/token-refresh',

    /**
      The identification attribute name. __This will be used in the request.__
      @property identificationAttributeName
      @type String
      @default 'username'
      @public
    */
    identificationAttributeName: 'username',

    /**
      The password attribute name. __This will be used in the request.__
      @property passwordAttributeName
      @type String
      @default 'password'
      @public
    */
    passwordAttributeName: 'password',

    /**
      Time (ms) before the JWT expires to call the serverRefreshTokenEndpoint
      @property refreshTokenOffset
      @type Integer
      @default '1000'
      @public
    */
    refreshTokenOffset: 1000,

    /**
      Time (ms) after a call to serverRefreshTokenEndpoint during which no
      further refresh token calls will be made.
       Used to reduce the number of refresh token calls made when the same
      app is simultaneously open in multiple tabs/windows.
       For example: if the JWT is set to expire 30s after being issued, and the
      'refreshTokenAfter' is set at 25s, requests may only be sent out in the
      last 5 seconds.
       @property refreshTokenAfter
      @type Integer
      @default '25000'
      @public
    */
    refreshTokenAfter: 25000,

    _refreshTokenTimeout: null,

    /**
      Restores the session from a session data object; __will return a resolving
      promise when there is a non-empty `access_token` in the session data__ and
      a rejecting promise otherwise.
      @method restore
      @param {Object} data The data to restore the session from
      @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming or remaining authenticated
      @public
    */
    restore: function restore(data) {
      if (this._refreshTokenTimeout) {
        Ember.run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
      }
      return this._refreshAccessToken(data);
    },


    /**
      Authenticates the session with the specified `identification` & `password`.
      Issues a `POST` request to the serverTokenEndpoint and receives the JWT token in response.
       If the credentials are valid and thus authentication succeeds, a promise that resolves with the
      server's response is returned, otherwise a promise that rejects with the error as returned by
      the server is returned.
       This method also schedules refresh requests for the access token before it expires.
      TODO: make the refresh token support optional
      @method authenticate
      @param {String} identification The resource owner username
      @param {String} password The resource owner password
      @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming authenticated
      @public
    */
    authenticate: function authenticate(identification, password) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var _data;

        var _getProperties = _this.getProperties('identificationAttributeName', 'passwordAttributeName'),
            identificationAttributeName = _getProperties.identificationAttributeName,
            passwordAttributeName = _getProperties.passwordAttributeName;

        var data = (_data = {}, _defineProperty(_data, identificationAttributeName, identification), _defineProperty(_data, passwordAttributeName, password), _data);
        var serverTokenEndpoint = _this.get('serverTokenEndpoint');

        _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
          Ember.run(function () {
            _this._validateParseRefreshToken(response);
            resolve(response);
          });
        }, function (response) {
          Ember.run(null, reject, response);
        });
      });
    },


    /**
      Deletes the JWT token
      @method invalidate
      @return {Ember.RSVP.Promise} A promise that when it resolves results in the session being invalidated
      @public
     */
    invalidate: function invalidate() {
      Ember.run.cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      return Ember.RSVP.Promise.resolve();
    },


    /**
      Makes a request to the JWT server.
      @method makeRequest
      @param {String} url The request URL
      @param {Object} data The request data
      @param {Object} headers Additional headers to send in request
      @return {Promise} A promise that resolves with the response object
      @protected
    */
    makeRequest: function makeRequest(url, data) {
      var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      headers['Content-Type'] = 'application/json';

      var options = {
        body: JSON.stringify(data),
        headers: headers,
        method: 'POST'
      };

      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _fetch.default)(url, options).then(function (response) {
          response.text().then(function (text) {
            var json = text ? JSON.parse(text) : {};
            if (!response.ok) {
              response.responseJSON = json;
              reject(response);
            } else {
              window.localStorage.setItem('jwtLastRefreshAt', Date.now());
              resolve(json);
            }
          });
        }).catch(reject);
      });
    },


    /**
      Validate that the response contains a valid JWT token
    */
    _validate: function _validate(data) {
      // Validate that a token is present
      if (Ember.isEmpty(data['token'])) {
        return false;
      }

      var jwtToken = data['token'].split('.');

      // Validate the three elements of a JWT are present
      if (jwtToken.length !== 3) {
        return false;
      }

      // Validate the JWT headers
      var jwtHeader = JSON.parse(atob(jwtToken[0]));
      if (jwtHeader.typ !== "JWT") {
        return false;
      }

      // Validate the JWT payload:
      // iat: issued at time
      // exp: expiration time
      var jwtPayload = JSON.parse(atob(jwtToken[1]));
      if (isNaN(jwtPayload['iat']) || isNaN(jwtPayload['exp'])) {
        return false;
      }

      return true;
    },
    _scheduleAccessTokenRefresh: function _scheduleAccessTokenRefresh(data) {
      var jwtPayload = JSON.parse(atob(data.token.split('.')[1]));
      var jwtPayloadExpiresAt = jwtPayload.exp;

      var offset = 1000; // Refresh 1 sec before JWT expires
      var now = Date.now();
      var waitMs = jwtPayloadExpiresAt * 1000 - now - offset; //expiresAt is in sec

      if (this._refreshTokenTimeout) {
        Ember.run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
      }

      // Reschedule if the JWT is still valid
      if (waitMs > 0) {
        this._refreshTokenTimeout = Ember.run.later(this, this._refreshAccessToken, data, waitMs);
      }
    },
    _refreshAccessToken: function _refreshAccessToken(data) {
      var _this2 = this;

      var timeElapsedSinceLastRefresh = Date.now() - window.localStorage.getItem('jwtLastRefreshAt');
      if (timeElapsedSinceLastRefresh <= this.get('refreshTokenAfter')) {
        // Request attempted too soon! Reschedule
        this._validateParseRefreshToken(data);
        return Ember.RSVP.Promise.resolve(data);
      }

      var serverRefreshTokenEndpoint = this.get('serverRefreshTokenEndpoint');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.makeRequest(serverRefreshTokenEndpoint, data).then(function (response) {
          Ember.run(function () {
            _this2._validateParseRefreshToken(response);
            _this2.trigger('sessionDataUpdated', response);
            resolve(response);
          });
        }, function (reason) {
          Ember.warn('Access token could not be refreshed - server responded with ' + JSON.stringify(reason.responseJSON) + '.', false, {
            id: 'ember-simple-auth-jwt.failedJWTTokenRefresh'
          });
          reject();
        });
      });
    },
    _validateParseRefreshToken: function _validateParseRefreshToken(response) {
      if (!this._validate(response)) {
        Ember.RSVP.Promise.reject('token is missing or invalid in server response');
      }

      this._scheduleAccessTokenRefresh(response);
    }
  });
});