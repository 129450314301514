define('ember-date-components/components/time-picker-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    classNames: [],

    attributeBindings: ['disabled'],

    type: 'text',

    /**
     * If this is true, the time picker is disabled and the selected time cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    KEY_EVENTS: Ember.computed(function () {
      return {
        38: 'arrowUp',
        40: 'arrowDown',
        13: 'enter',
        27: 'escape'
      };
    }),

    interpretKeyEvents: function interpretKeyEvents(event) {
      if (!event) {
        return this.inputChange();
      }
      var map = Ember.get(this, 'KEY_EVENTS');
      var keyCode = event.keyCode;


      var method = map[keyCode];
      if (method) {
        return this[method](event);
      } else {
        return Ember.run.once(this, this.inputChange);
      }
    },
    change: function change() {
      Ember.run.once(this, this.inputChange);
    },
    inputChange: function inputChange() {
      this._elementValueDidChange();
      var value = Ember.get(this, 'value');

      var action = Ember.get(this, 'input-change');
      if (action && typeof action === 'function') {
        return action(value, this);
      } else {
        console.warn('input-change action on time-picker-input needs to be a closure action.'); // eslint-disable-line
      }
    },
    arrowUp: function arrowUp(event) {
      var action = Ember.get(this, 'arrow-up');

      if (action && typeof action === 'function') {
        return action(this, event);
      } else {
        console.warn('arrow-up action on time-picker-input needs to be a closure action.'); // eslint-disable-line
      }
    },
    arrowDown: function arrowDown(event) {
      var action = Ember.get(this, 'arrow-down');

      if (action && typeof action === 'function') {
        return action(this, event);
      } else {
        console.warn('arrow-down action on time-picker-input needs to be a closure action.'); // eslint-disable-line
      }
    },
    escape: function escape(event) {
      var action = Ember.get(this, 'escape');

      if (action && typeof action === 'function') {
        return action(this, event);
      } else {
        console.warn('escape action on time-picker-input needs to be a closure action.'); // eslint-disable-line
      }
    },
    enter: function enter(event) {
      var action = Ember.get(this, 'enter');

      if (action && typeof action === 'function') {
        return action(this, event);
      } else {
        console.warn('enter action on time-picker-input needs to be a closure action.'); // eslint-disable-line
      }
    }
  });
});