define("ember-models-table/components/models-table/select", ["exports", "ember-models-table/templates/components/models-table/select"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Select-dropdown with a list of options. Used as page-size select and for select-filters.
   *
   * @class ModelsTableSelect
   * @namespace Components
   * @extends Ember.Component
   * @private
   */
  var _default = _exports.default = Ember.Component.extend({
    layout: _select.default,
    tagName: 'select',
    attributeBindings: ['disabled'],
    classNameBindings: ['cssPropertyName', 'themeInstance.input', 'themeInstance.select'],
    disabled: Ember.computed.empty('options'),
    /**
     * @type string
     * @default ''
     * @property type
     */
    type: '',
    /**
     * @type string
     * @default ''
     * @property cssPropertyName
     */
    cssPropertyName: '',
    /**
     * Bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     *
     * @property themeInstance
     * @type object
     * @default null
     */
    themeInstance: null,
    change: function change() {
      var val = this.element.querySelector('option:checked').value;
      if (Ember.get(this, 'type') === 'number') {
        val = +val;
      }
      Ember.set(this, 'value', val);
    }
  });
});