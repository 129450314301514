define("ember-file-upload/queue", ["exports", "ember-file-upload/file", "ember-file-upload/computed/sum-by"], function (_exports, _file, _sumBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    The Queue is a collection of files that
    are being manipulated by the user.
  
    Queues are designed to persist the state
    of uploads when a user navigates around your
    application.
  
    @class Queue
    @extends Ember.Object
   */
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      Ember.set(this, 'files', Ember.A());
      Ember.set(this, '_dropzones', Ember.A());
      this._super();
    },
    destroy: function destroy() {
      this._super();
      Ember.get(this, 'fileQueue.queues').delete(Ember.get(this, 'name'));
      Ember.get(this, 'files').forEach(function (file) {
        return Ember.set(file, 'queue', null);
      });
      Ember.set(this, 'files', Ember.A());
    },
    /**
      The FileQueue service.
       @property fileQueue
      @type FileQueue
     */
    fileQueue: null,
    /**
      @method push
      @param {File} file The file to append to the queue
     */
    push: function push(file) {
      file.queue = this;
      Ember.get(this, 'fileQueue.files').pushObject(file);
      Ember.get(this, 'files').pushObject(file);
    },
    /**
      @private
      @param {FileList} fileList The event triggered from the DOM that contains a list of files
     */
    _addFiles: function _addFiles(fileList, source) {
      var onfileadd = Ember.get(this, 'onfileadd');
      var disabled = Ember.get(this, 'disabled');
      var files = [];
      if (!disabled) {
        for (var i = 0, len = fileList.length || fileList.size; i < len; i++) {
          var fileBlob = fileList.item ? fileList.item(i) : fileList[i];
          if (fileBlob instanceof Blob) {
            var file = _file.default.fromBlob(fileBlob, source);
            files.push(file);
            this.push(file);
            if (onfileadd) {
              Ember.run.next(onfileadd, file);
            }
          }
        }
      }
      return files;
    },
    /**
      @method remove
      @param {File} file The file to remove from the queue.
     */
    remove: function remove(file) {
      file.queue = null;
      Ember.get(this, 'fileQueue.files').removeObject(file);
      Ember.get(this, 'files').removeObject(file);
    },
    /**
      The unique identifier of the queue.
       Queue names should be deterministic so they
      can be retrieved. It's recommended to provide
      a helpful name.
       If the queue belongs to a top level collection,
      photos, the good name for this queue may be `"photos"`.
       If you're uploading images to an artwork, the
      best name would incoporate both `"artworks"` and
      the identifier of the artwork. A good name for this
      queue may be `"artworks/{{id}}/photos"`, where `{{id}}`
      is a dynamic segment that is generated from the artwork id.
       @property name
      @type string
      @default null
     */
    name: null,
    /**
      The list of files in the queue. This automatically gets
      flushed when all the files in the queue have settled.
       Note that files that have failed need to be manually
      removed from the queue. This is so they can be retried
      without resetting the state of the queue, orphaning the
      file from its queue. Upload failures can happen due to a
      timeout or a server response. If you choose to use the
      `abort` method, the file will fail to upload, but will
      be removed from the requeuing proccess, and will be
      considered to be in a settled state.
       @property files
      @type File[]
      @default []
     */
    files: null,
    /**
      Flushes the `files` property when they have settled. This
      will only flush files when all files have arrived at a terminus
      of their state chart.
       ```
          .------.     .---------.     .--------.
      o--| queued |-->| uploading |-->| uploaded |
          `------`     `---------`     `--------`
             ^              |    .-------.
             |              |`->| aborted |
             |              |    `-------`
             |  .------.    |    .---------.
             `-| failed |<-` `->| timed_out |-.
             |  `------`         `---------`  |
             `-------------------------------`
      ```
       Files *may* be requeued by the user in the `failed` or `timed_out`
      states.
       @private
     */
    flushFilesWhenSettled: Ember.observer('files.@each.state', function () {
      // eslint-disable-line ember/no-observers
      var files = Ember.get(this, 'files');
      var allFilesHaveSettled = files.every(function (file) {
        return ['uploaded', 'aborted'].indexOf(file.state) !== -1;
      });
      if (files.length === 0) {
        return;
      }
      if (allFilesHaveSettled) {
        Ember.get(this, 'files').forEach(function (file) {
          return Ember.set(file, 'queue', null);
        });
        Ember.set(this, 'files', Ember.A());
      }
    }),
    /**
      The aggregate size (in bytes) of all files in the queue.
       @accessor size
      @readonly
      @type number
      @default 0
     */
    size: (0, _sumBy.default)('files', 'size'),
    /**
      The aggregate amount of bytes that have been uploaded
      to the server for all files in the queue.
       @accessor loaded
      @readonly
      @type number
      @default 0
     */
    loaded: (0, _sumBy.default)('files', 'loaded'),
    /**
      The current upload progress of the queue, as a number from 0 to 100.
       @accessor progress
      @readonly
      @type number
      @default 0
     */
    progress: Ember.computed('size', 'loaded', {
      get: function get() {
        var percent = Ember.get(this, 'loaded') / Ember.get(this, 'size') || 0;
        return Math.floor(percent * 100);
      }
    })
  });
});