define("ember-models-table/initializers/emt-themes", ["exports", "ember-models-table/themes/default", "ember-models-table/themes/bootstrap3", "ember-models-table/themes/bootstrap4", "ember-models-table/themes/semanticui", "ember-models-table/themes/ember-semanticui", "ember-models-table/themes/ember-bootstrap-v3", "ember-models-table/themes/ember-bootstrap-v4"], function (_exports, _default2, _bootstrap, _bootstrap2, _semanticui, _emberSemanticui, _emberBootstrapV, _emberBootstrapV2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('emt-theme:default', _default2.default, {
      singleton: false
    });
    application.register('emt-theme:bootstrap3', _bootstrap.default, {
      singleton: false
    });
    application.register('emt-theme:bootstrap4', _bootstrap2.default, {
      singleton: false
    });
    application.register('emt-theme:semanticui', _semanticui.default, {
      singleton: false
    });
    application.register('emt-theme:ember-semanticui', _emberSemanticui.default, {
      singleton: false
    });
    application.register('emt-theme:ember-bootstrap-v3', _emberBootstrapV.default, {
      singleton: false
    });
    application.register('emt-theme:ember-bootstrap-v4', _emberBootstrapV2.default, {
      singleton: false
    });
  }
  var _default = _exports.default = {
    name: 'emt-themes',
    initialize: initialize
  };
});