define("ember-file-upload/mirage/utils", ["exports", "ember-file-upload/system/file-reader"], function (_exports, _fileReader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extractFileMetadata = extractFileMetadata;
  _exports.extractFormData = extractFormData;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function extractFormData(formData) {
    var data = {};
    var items = formData.entries();
    var item = items.next();
    var file = null;
    while (!item.done) {
      var _item$value = _slicedToArray(item.value, 2),
        key = _item$value[0],
        value = _item$value[1];
      if (value instanceof Blob) {
        file = {
          key: key,
          value: value
        };
      } else {
        data[key] = value;
      }
      item = items.next();
    }
    return {
      file: file,
      data: data
    };
  }
  var pipelines = {
    gif: function gif(file) {
      var reader = new _fileReader.default();
      return reader.readAsArrayBuffer(file).then(function (buffer) {
        var data = new Uint8Array(buffer);
        var duration = 0;
        for (var i = 0; i < data.length; i++) {
          // Find a Graphic Control Extension hex(21F904__ ____ __00)
          if (data[i] === 0x21 && data[i + 1] === 0xF9 && data[i + 2] === 0x04 && data[i + 7] === 0x00) {
            // Swap 5th and 6th bytes to get the delay per frame
            var delay = data[i + 5] << 8 | data[i + 4] & 0xFF;

            // Should be aware browsers have a minimum frame delay
            // e.g. 6ms for IE, 2ms modern browsers (50fps)
            duration += delay < 2 ? 10 : delay;
          }
        }
        return {
          duration: duration / 1000,
          animated: duration > 0
        };
      });
    },
    image: function image(file, metadata) {
      return new Ember.RSVP.Promise(function (resolve) {
        var img = new Image();
        img.onload = function () {
          resolve(img);
        };
        img.src = metadata.url;
      }).then(function (img) {
        return {
          width: img.naturalWidth,
          height: img.naturalHeight
        };
      });
    },
    video: function video(file, metadata) {
      var video = document.createElement('video');
      return new Ember.RSVP.Promise(function (resolve) {
        video.addEventListener('loadeddata', resolve);
        video.src = metadata.url;
        document.body.appendChild(video);
        video.load();
      }).then(function () {
        return {
          duration: video.duration,
          width: video.videoWidth,
          height: video.videoHeight
        };
      }).finally(function () {
        document.body.removeChild(video);
      });
    },
    audio: function audio(file, metadata) {
      var audio = document.createElement('audio');
      return new Ember.RSVP.Promise(function (resolve) {
        audio.addEventListener('loadeddata', resolve);
        audio.src = metadata.url;
        document.body.appendChild(audio);
        audio.load();
      }).then(function () {
        return {
          duration: audio.duration
        };
      }).finally(function () {
        document.body.removeChild(audio);
      });
    }
  };
  function extractFileMetadata(file) {
    var metadata = {
      name: file.name,
      size: file.size,
      type: file.type,
      extension: (file.name.match(/\.(.*)$/) || [])[1]
    };
    var reader = new _fileReader.default();
    return reader.readAsDataURL(file).then(function (url) {
      metadata.url = url;
      var additionalMetadata = [];
      if (metadata.type === 'image/gif') {
        additionalMetadata.push(pipelines.gif(file, metadata));
      }
      if (metadata.type.match(/^image\//)) {
        additionalMetadata.push(pipelines.image(file, metadata));
      }
      if (metadata.type.match(/^video\//)) {
        additionalMetadata.push(pipelines.video(file, metadata));
      }
      if (metadata.type.match(/^audio\//)) {
        additionalMetadata.push(pipelines.audio(file, metadata));
      }
      return Ember.RSVP.all(additionalMetadata);
    }).then(function (additionalMetadata) {
      additionalMetadata.forEach(function (data) {
        Object.assign(metadata, data);
      });
      return metadata;
    });
  }
});