define('ember-date-components/utils/should-use-am-pm', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shouldUseAmPm = shouldUseAmPm;
  function shouldUseAmPm() {
    return (0, _moment.default)().startOf('day').format('LLL').toLowerCase().indexOf('am') > -1;
  }

  exports.default = shouldUseAmPm;
});