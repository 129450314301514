define('ember-crumbly/components/bread-crumbs', ['exports', 'ember', 'ember-crumbly/templates/components/bread-crumbs'], function (exports, _ember, _emberCrumblyTemplatesComponentsBreadCrumbs) {
  var _get = _ember['default'].get;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var getWithDefault = _ember['default'].getWithDefault;
  var assert = _ember['default'].assert;
  var deprecate = _ember['default'].deprecate;
  var isPresent = _ember['default'].isPresent;
  var typeOf = _ember['default'].typeOf;
  var setProperties = _ember['default'].setProperties;
  var getOwner = _ember['default'].getOwner;
  var emberArray = _ember['default'].A;
  var classify = _ember['default'].String.classify;
  var bool = computed.bool;
  var readOnly = computed.readOnly;
  exports['default'] = Component.extend({
    layout: _emberCrumblyTemplatesComponentsBreadCrumbs['default'],
    tagName: 'ol',
    linkable: true,
    reverse: false,
    classNameBindings: ['breadCrumbClass'],
    hasBlock: bool('template').readOnly(),
    currentUrl: readOnly('applicationRoute.router.url'),
    currentRouteName: readOnly('applicationRoute.controller.currentRouteName'),

    routeHierarchy: computed('currentUrl', 'currentRouteName', 'reverse', {
      get: function get() {
        var currentRouteName = getWithDefault(this, 'currentRouteName', false);

        assert('[ember-crumbly] Could not find a current route', currentRouteName);

        var routeNames = currentRouteName.split('.');
        var filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
        var crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

        return _get(this, 'reverse') ? crumbs.reverse() : crumbs;
      }
    }).readOnly(),

    breadCrumbClass: computed('outputStyle', {
      get: function get() {
        var className = 'breadcrumb';
        var outputStyle = getWithDefault(this, 'outputStyle', '');
        if (isPresent(outputStyle)) {
          deprecate('outputStyle option will be deprecated in the next major release', false, { id: 'ember-crumbly.outputStyle', until: '2.0.0' });
        }
        var lowerCaseOutputStyle = outputStyle.toLowerCase();

        if (lowerCaseOutputStyle === 'foundation') {
          className = 'breadcrumbs';
        }

        return className;
      }
    }).readOnly(),

    _guessRoutePath: function _guessRoutePath(routeNames, name, index) {
      var routes = routeNames.slice(0, index + 1);

      if (routes.length === 1) {
        var path = name + '.index';

        return this._lookupRoute(path) ? path : name;
      }

      return routes.join('.');
    },

    _filterIndexAndLoadingRoutes: function _filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(function (name) {
        return !(name === 'index' || name === 'loading');
      });
    },

    _lookupRoute: function _lookupRoute(routeName) {
      return getOwner(this).lookup('route:' + routeName);
    },

    _lookupBreadCrumb: function _lookupBreadCrumb(routeNames, filteredRouteNames) {
      var _this = this;

      var defaultLinkable = _get(this, 'linkable');
      var pathLength = filteredRouteNames.length;
      var breadCrumbs = emberArray();

      filteredRouteNames.map(function (name, index) {
        var path = _this._guessRoutePath(routeNames, name, index);
        var route = _this._lookupRoute(path);
        var isHead = index === 0;
        var isTail = index === pathLength - 1;

        var crumbLinkable = index === pathLength - 1 ? false : defaultLinkable;

        assert('[ember-crumbly] `route:' + path + '` was not found', route);

        var multipleBreadCrumbs = route.get('breadCrumbs');

        if (multipleBreadCrumbs) {
          multipleBreadCrumbs.forEach(function (breadCrumb) {
            breadCrumbs.pushObject(breadCrumb);
          });
        } else {
          var breadCrumb = copy(getWithDefault(route, 'breadCrumb', {
            title: classify(name)
          }));

          if (typeOf(breadCrumb) === 'null') {
            return;
          } else {
            if (isPresent(breadCrumb.path)) {
              path = breadCrumb.path;
            }

            setProperties(breadCrumb, {
              path: path,
              isHead: isHead,
              isTail: isTail,
              linkable: breadCrumb.hasOwnProperty('linkable') ? breadCrumb.linkable : crumbLinkable
            });
          }

          breadCrumbs.pushObject(breadCrumb);
        }
      });

      return emberArray(breadCrumbs.filter(function (breadCrumb) {
        return typeOf(breadCrumb) !== 'undefined';
      }));
    }
  });
});