define('ember-confirm-dialog/components/confirm-dialog', ['exports', 'ember-confirm-dialog/templates/components/confirm-dialog'], function (exports, _confirmDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _confirmDialog.default,
    classNames: ['confirm-dialog-button'],

    //---public
    confirmAction: function confirmAction() {},
    //optional action executed when user confirms the dialog
    cancelAction: function cancelAction() {},
    //optional action executed when user cancels confirmation dialog
    title: '',
    text: 'Do you want to proceed?',
    dialogClass: '',
    confirmButton: 'OK',
    cancelButton: 'Cancel',
    confirmButtonClass: '',
    cancelButtonClass: '',
    okCssIcon: null,
    cancelCssIcon: null,
    disabled: false,
    active: true,

    //---private
    isShowingModal: false,
    confirmed: false,
    event: null,

    initComponent: function initComponent() {
      var _this = this;

      Ember.$(this.element).find('.confirm-wrapper').on('click', function (event) {

        if (!_this.get('active')) {

          return;
        }
        event.stopPropagation();

        if (!_this.get('disabled')) {
          Ember.run(function () {
            _this.set('event', event);
            _this.toggleProperty('isShowingModal', true);
          });
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.initComponent();
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(this.element).find('.confirm-wrapper').off();
    },


    actions: {
      confirm: function confirm() {
        var _this2 = this;

        this.set('confirmed', false);
        this.set('isShowingModal', false);

        Ember.$(this.element).find('.confirm-wrapper').off();

        this.get('confirmAction')();

        Ember.$(this.element).find('.confirm-wrapper').children().each(function (index, elem) {
          Ember.$(elem).find('[data-ember-action]').addBack('[data-ember-action]').trigger('click', _this2.get('event'));
        });

        this.initComponent();
      },
      cancel: function cancel() {
        var _this3 = this;

        this.toggleProperty('isShowingModal', false);

        Ember.run.scheduleOnce('afterRender', this, function () {
          _this3.get('cancelAction')();
        });
      }
    }

  });
});