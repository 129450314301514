define('ember-simple-auth-jwt/authorizers/jwt', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    /**
      The token attribute name.
      @property tokenAttributeName
      @type String
      @default 'token'
      @public
    */
    tokenAttributeName: 'token',

    /**
      Authorizes an XHR request by sending the `token`
      properties from the session in the `Authorization` header:
      ```
      Authorization: Bearer <token>
      ```
      @method authorize
      @param {object} data
      @param {function} block
    */
    authorize: function authorize() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var block = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

      if (!Ember.isEmpty(data) && !Ember.isEmpty(data.token)) {
        block('Authorization', 'Bearer ' + data.token);
      }
    }
  });
});