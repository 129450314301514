define("ember-changeset-validations/utils/validation-errors", ["exports", "ember-get-config", "ember-changeset-validations/utils/get-messages"], function (_exports, _emberGetConfig, _getMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildMessage;
  function buildMessage(key, result) {
    var returnsRaw = Ember.getWithDefault(_emberGetConfig.default, 'changeset-validations.rawOutput', false);
    var messages = (0, _getMessages.default)();
    var description = messages.getDescriptionFor(key);
    if (result.message) {
      return result.message;
    }
    var type = result.type,
      value = result.value,
      _result$context = result.context,
      context = _result$context === void 0 ? {} : _result$context;
    if (context.message) {
      var _message = context.message;
      if (Ember.typeOf(_message) === 'function') {
        var builtMessage = _message(key, type, value, context);
        (false && !(Ember.typeOf(builtMessage) === 'string') && Ember.assert('Custom message function must return a string', Ember.typeOf(builtMessage) === 'string'));
        return builtMessage;
      }
      return messages.formatMessage(_message, Ember.assign({
        description: description
      }, context));
    }
    var message = Ember.get(messages, type);
    if (returnsRaw) {
      context = Ember.assign({}, context, {
        description: description
      });
      return {
        value: value,
        type: type,
        message: message,
        context: context
      };
    } else {
      return messages.formatMessage(message, Ember.assign({
        description: description
      }, context));
    }
  }
});