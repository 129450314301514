define("ember-file-upload/helpers/file-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    `file-queue` helper is one of the core primitives of ember-file-upload.
    It has three different flavors of invocation. The first is
    a bare call, which will return the master queue:
  
    ```hbs
    {{#with (file-queue) as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    If called with a `name` property, it will return the queue
    of that name:
  
    ```hbs
    {{#with (file-queue name="photos") as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    @class FileQueue
    @param {string} [name] The name of the queue to get information on
    @return {Queue} A collection of all queues, or a specific queue.
   */
  var _default = _exports.default = Ember.Helper.extend({
    fileQueue: Ember.inject.service(),
    compute: function compute(_, hash) {
      var queues = Ember.get(this, 'fileQueue');
      var queueName = hash['name'];
      if (queueName) {
        var queue = queues.find(queueName) || queues.create(queueName);
        return queue;
      }
      return queues;
    }
  });
});